import { ExternalCta } from '@/common/ctas';
import SlideUp from '../../common/utils/slideUp';
import ZoomIn from '../../common/utils/zoomIn';
import parsePhoneNumber from 'libphonenumber-js';
import Markdown from 'marked-react';
import Image from 'next/image';
import { PlaceholderSvg } from '@/common/svgs/placeholderSvg';
import clsx from 'clsx';
import { Homepage } from '@/typings/types';

type IProps = {
  stores: Homepage['locations']['stores'];
};

const StoreRenderer = ({ stores }: IProps) => {
  return (
    <div
      className={
        'mx-auto grid w-9/12 max-w-[330px] gap-y-8 md:max-w-max md:grid-flow-row md:grid-cols-2 md:gap-x-8 lg:w-auto lg:grid-flow-row lg:grid-cols-8 xl:grid-cols-12 lg:gap-y-8'
      }
    >
      {stores?.map((store, index) => {
        const { image } = store ?? {};
        const { url, alternativeText, placeholder } = image?.data?.attributes ?? {};

        // Cycle index between 0, 1, and 2
        const cycledIndex = index % 3;

        let color: string;
        let textColor = '';
        switch (cycledIndex) {
          case 0:
            color = 'bg-chocolate border-chocolate';
            textColor = 'text-chocolate';
            break;
          case 1:
            color = 'bg-caramel border-caramel';
            textColor = 'text-caramel';
            break;
          case 2:
            color = 'bg-chocolate-light border-chocolate-light';
            textColor = 'text-chocolate-light';
            break;
          default:
            color = 'bg-chocolate'; // Default case for safety
        }

        let phoneNumber;
        if (store?.phone) {
          phoneNumber = parsePhoneNumber(store.phone, 'FR');
        }

        return (
          <section
            key={store?.id}
            className={`w-full overflow-hidden pb-10 text-white ${color} col-span-4 3xl:col-span-3`}
          >
            <div className={'flex flex-col items-stretch h-full'}>
              <SlideUp
                delay
                yOffset={-5}
                className={`overflow-hidden border-[0.6rem] pb-5 ${color}`}
              >
                <ZoomIn
                  zoom={1.2}
                  triggerOnce={false}
                  className={clsx(
                    'flex items-center justify-center relative h-[330px] w-full overflow-hidden lg:w-[330px]',
                    {
                      'bg-chocolate-placeholder': cycledIndex === 2,
                      'bg-caramel-placeholder': cycledIndex === 1,
                    }
                  )}
                >
                  {url ? (
                    <Image
                      src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${url}?resize=330x330`}
                      fill
                      className={'object-cover'}
                      alt={alternativeText ?? ''}
                      placeholder={placeholder ? 'blur' : 'empty'}
                      blurDataURL={placeholder ?? ''}
                    />
                  ) : (
                    <PlaceholderSvg />
                  )}
                </ZoomIn>
              </SlideUp>

              <div
                className={
                  'flex flex-col items-start justify-center pl-5 pr-10 lg:max-w-[310px] flex-1'
                }
              >
                <SlideUp yOffset={-10}>
                  <h2
                    className={'pb-4 font-stigsaMedium text-3xl uppercase leading-7 tracking-wide'}
                  >
                    <span className={'text-xl'}>Ma Boulangerie</span>
                    <span className={'block'}>{store?.name}</span>
                  </h2>
                </SlideUp>
                <SlideUp
                  yOffset={-5}
                  delay
                  className={'pb-8 font-libertineRegular text-lg leading-6 flex-1 flex'}
                >
                  <Markdown>{store?.location}</Markdown>
                </SlideUp>
                <SlideUp yOffset={-5} delay>
                  <h3 className={'font-libertineRegular text-lg font-bold flex-1'}>
                    Horaires d&apos;ouverture :
                  </h3>
                </SlideUp>
                <SlideUp
                  yOffset={-5}
                  delay
                  className={'pb-10 font-libertineRegular text-lg leading-6 flex-1'}
                >
                  <Markdown>{store?.hours}</Markdown>
                </SlideUp>
                <SlideUp yOffset={-5} delay className={'mb-6'}>
                  {phoneNumber && store?.phone && (
                    <ExternalCta
                      backgroundColor={'bg-white'}
                      textColor={textColor}
                      link={phoneNumber.getURI()}
                      text={store?.phone}
                    />
                  )}
                </SlideUp>
                <SlideUp yOffset={-5} delay>
                  {store?.maps && (
                    <ExternalCta
                      backgroundColor={'bg-none'}
                      textColor={'text-white'}
                      link={store?.maps}
                      text={'voir le plan'}
                      className={'border border-white'}
                    />
                  )}
                </SlideUp>
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default StoreRenderer;
